@import '../../../styles/vars';

.design__section {
    padding-bottom: 9rem;
}

.heading {
    text-align: left;
    color: $dark;
    padding-bottom: 0.75rem;
    line-height: 70%;
    max-width: 820px;
    .word__wrapper {
        line-height: 100%;
        span {
            transform: translateY(100%);
            line-height: 110%;
            vertical-align: middle;
        }
    }
    span {
        display: inline-block;
    }
}

.excerpt {
    padding-bottom: 4rem;
    line-height: 73%;
    max-width: 30.625rem;
    .word__wrapper {
        line-height: 100%;
        span {
            transform: translateY(100%);
            line-height: 140%;
            vertical-align: middle;
        }
    }
    span {
        display: inline-block;
    }
}

.word__wrapper {
    overflow-y: hidden;
    overflow-x: auto;
}

.animate__heading {
    .word__wrapper span {
        animation: $headingFade;
    }
}

.animate__excerpt {
    .word__wrapper span {
        animation: $excerptFade;
    }
}

.features {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;

    &__row {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr;
        column-gap: 2rem;

        &--2 {
            grid-template-columns: repeat(2, 1fr);
        }

        &--3 {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (max-width: 1700px) {
    .design__section {
        padding-bottom: 7.5rem;
    }
}

@media (max-width: 1480px) {
    .design__section {
        padding-bottom: 6.5rem;
    }

    .features {
        row-gap: 3rem;
    }

    .excerpt {
        padding-bottom: 3rem;
    }
}

@media (max-width: 1200px) {
    .design__section {
        padding-bottom: 5rem;
    }

    .features {
        row-gap: 2.5rem;

        &__row {
            column-gap: 1.5rem;
        }
    }

    .excerpt {
        padding-bottom: 2.5rem;
    }
}

@media (max-width: 1100px) {
    .features {
        &__row {
            &--2,
            &--3 {
                grid-template-columns: 1fr;
                column-gap: 0;
                row-gap: 2.5rem;
            }
        }
    }
}

@media (max-width: 768px) {
    .design__section {
        padding-bottom: 4rem;
    }

    .features {
        row-gap: 2rem;

        &__row {
            row-gap: 2rem;
        }
    }
}

@media (max-width: 576px) {
    .design__section {
        padding-bottom: 3rem;
    }

    .heading {
        padding-bottom: 0.5rem;
    }

    .features {
        row-gap: 1.5rem;

        &__row {
            row-gap: 1.5rem;
        }
    }

    .excerpt {
        padding-bottom: 2rem;
    }
}
