@import '../../../styles/vars';
@import '../../../styles/keyframes.scss';

.heading {
    padding-bottom: 1rem;
    text-align: center;
    line-height: 68%;
    max-width: 720px;
    .word__wrapper {
        line-height: 100%;

        span {
            transform: translateY(100%);
            line-height: 112%;
            vertical-align: middle;
        }
    }

    span {
        display: inline-block;
    }
}

.excerpt {
    max-width: 600px;
    padding-bottom: 4rem;
    text-align: center;
    line-height: 73%;
    margin: auto;

    .word__wrapper {
        line-height: 100%;

        span {
            transform: translateY(100%);
            line-height: 140%;
            vertical-align: middle;
        }
    }

    span {
        display: inline-block;
    }
}

.word__wrapper {
    overflow-y: hidden;
    overflow-x: auto;
}

.animate__heading {
    .word__wrapper span {
        animation: $headingFade;
    }
}

.animate__excerpt {
    .word__wrapper span {
        animation: $excerptFade;
    }
}

.demos {
    .heading,
    .excerpt {
        text-align: left;
        margin: 0;
    }
}

.bundle {
    max-height: 680px;
    height: 680px;

    h2 {
        max-width: 692px;
    }

    h2 svg {
        bottom: -6px !important;
    }
}

.themes__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;
    padding-bottom: 9rem;
}

.advantage__section {
    padding: 9rem 0;
    .heading {
        max-width: 820px;
        margin: auto;
    }
}

@media (min-width: 1921px) {
    .bundle {
        height: 50rem;
        max-height: 50rem;
    }
}

@media (max-width: 1700px) {
    .advantage__section {
        padding: 7.5rem 0;
    }

    .themes__list {
        padding-bottom: 7.5rem;
    }
}

@media (max-width: 1480px) {
    .excerpt {
        padding-bottom: 3rem;
    }

    .advantage__section {
        padding: 6rem 0;
    }

    .themes__list {
        padding-bottom: 6.5rem;
    }

    .bundle {
        height: 40vw;
    }
}

@media (max-width: 1200px) {
    .themes__list {
        padding-bottom: 5rem;
        grid-row-gap: 3rem;
    }

    .heading {
        // max-width: ;
    }

    .excerpt {
        padding-bottom: 2.5rem;
        max-width: 500px;
    }

    .bundle {
        height: 42vw;
    }

    .advantage__section {
        padding: 5rem 0;
        .heading {
            max-width: 600px;
        }
    }
}

@media (max-width: 1024px) {
    .themes__list {
        grid-column-gap: 1.5rem;
        grid-row-gap: 2.5rem;
        grid-template-columns: repeat(2, 1fr);
    }

    .bundle {
        height: 48vw;
        h2 {
            max-width: 460px;
        }
    }
}

@media (max-width: 820px) {
    .bundle {
        height: 51vw;
    }

    .advantage__section {
        .excerpt,
        .heading {
            text-align: left;
            margin: 0;
        }
    }
}

@media (max-width: 768px) {
    .themes__list {
        grid-row-gap: 2rem;
        padding-bottom: 4rem;
    }

    .bundle {
        height: auto;
        max-height: 100%;
    }

    .advantage__section {
        padding: 4rem 0;
    }
}

@media (max-width: 576px) {
    .themes__list {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0;
        padding-bottom: 3rem;
    }

    .advantage__section {
        padding: 3rem 0;
    }

    .excerpt {
        padding-bottom: 2rem;
    }
}

@media (max-width: 480px) {
    .heading {
        padding-bottom: 1rem;
    }
}
