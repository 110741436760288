@import '../../styles/vars';

.buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: fit-content;
}

.top {
    display: flex;
    gap: 0.75rem;
}

.divider {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.line {
    flex-grow: 1;
    display: block;
    height: 2px;
    border-radius: 10px;
    background: #e4e5ea;
}

.letter {
    flex-shrink: 0;
    color: #9da2b1;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.01em;
}

.special {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.special__text {
    display: block;
    color: $dark;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    position: relative;
    &::after {
        display: block;
        content: '';
        height: 0.875rem;
        width: 2px;
        background: #e4e5ea;
        position: absolute;
        top: 50%;
        right: -0.75rem;
        transform: translate(50%, -50%);
        border-radius: 100px;
    }
}

.bundle {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    transition: $anim;
    span {
        display: inline-block;
        position: relative;
        z-index: 3;
        color: #4769d8;
        transition: all 0.4s ease-in-out;
        font-size: 15px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.03em;
        text-transform: capitalize;
        cursor: pointer;
        &::after {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            bottom: -2.5px;
            width: 100%;
            height: 2px;
            background: #4769d8;
            z-index: -1;
            visibility: visible;
            transition-delay: 0.3s;
            transition: width 0.3s ease-in-out;
        }
    }
    svg:last-child {
        display: none;
    }
    svg path {
        stroke: #4769d8;
    }
    .colored {
        display: block;
        border: none;
        border-radius: 100px;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        padding: 0.375rem 0.75rem;
        background: #4769d8;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
    }
    &:hover {
        span {
            color: #597be8;
            &::after {
                width: 0;
            }
        }

        .colored {
            background: #597be8;
        }

        svg path {
            stroke: #597be8;
        }
    }
}

@media (max-width: 1200px) {
    .buttons {
        gap: 0.75rem;
    }

    .letter {
        font-size: 1rem;
    }

    .bundle {
        span {
            font-size: 0.875rem;
        }
    }

    .special__text {
        font-size: 0.875rem;
    }
}

@media (max-width: 1024px) {
    .buttons {
        gap: 1rem;
    }
}

@media (max-width: 576px) {
    .buttons {
        width: 100%;
    }
    .top {
        flex-direction: column;
        align-items: center;
        button,
        a {
            width: 100%;
        }
    }
}

@media (max-width: 480px) {
    .special {
        flex-direction: column;
        gap: 0.5rem;
    }

    .special__text {
        width: 100%;
        text-align: center;
        &::after {
            display: none;
        }
    }
}
