@import '../../styles/vars';

@keyframes cardAnimation {
    0% {
        opacity: 0;
        transform: scale(0.94);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

.theme {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: 3rem 3rem 0;
    overflow: hidden;
    transition:
        background-color 0.4s ease-in-out,
        border 0.4s ease-in-out;
    animation-duration: 0.5s;
    opacity: 0;
    animation-fill-mode: forwards;

    &--animate {
        animation-name: fadeInUp, fadeIn;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1);
    }

    &--light {
        background-color: #f5f6f7;

        .heading {
            color: $dark;
        }

        .excerpt {
            color: $excerpt;
        }

        .handler {
            background: #ebecef;
        }

        .button {
            transform: translate(0, -50%);
        }

        .sun path {
            fill: $dark;
        }

        .desktop__wrapper,
        .mobile__wrapper {
            fill: $white;
        }

        .desktop__section,
        .mobile__section {
            fill: #f1f1f2;
        }

        .mobile__fill {
            stroke: #e4e5ea;
        }
    }

    &--dark {
        background-color: #0d0e14;

        .heading {
            color: $white;
        }

        .excerpt {
            color: #bbbbbc;
        }

        .button {
            transform: translate(calc(100% + 1px), -50%);
        }

        .handler {
            background: rgba(255, 255, 255, 0.16);
        }

        .sun path {
            fill: $white;
        }

        .desktop__wrapper,
        .mobile__wrapper {
            fill: #171922;
        }

        .desktop__section,
        .mobile__section {
            fill: #272936;
        }

        .mobile__fill {
            stroke: #2c3040;
        }
    }

    &--large {
        padding: 4rem 4rem 0;

        .theme__top {
            gap: 1.5rem;
        }

        .excerpt {
            font-size: 1.25rem;
            margin: 0.75rem 0 2.5rem;
            max-width: 520px;
        }

        .try {
            width: 93px;
        }

        .arrow {
            width: 60px;
            bottom: -5px;
            left: 55px;
        }

        .handler {
            width: 102px;
        }

        .button {
            width: 40px;
            height: 40px;
        }

        .icon {
            width: 26px;
            height: 26px;
        }

        &.theme--dark {
            .button {
                transform: translate(calc(100% + 10px), -50%);
            }
        }

        .devices {
            padding-bottom: 4rem;
        }

        .desktop {
            width: 84.57%;
            transform: none;
        }

        .label {
            width: 140px;
            bottom: 30%;
        }
    }
}

.icon {
    &.sun {
        path {
            transition: fill 0.4s ease;
        }
    }
}

.theme__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}

.desktop__section,
.desktop__wrapper,
.mobile__section,
.mobile__wrapper,
.mobile__fill {
    transition:
        fill 0.4s ease-in-out,
        stroke 0.4s ease-in-out;
}

.heading {
    transition: color 0.4s ease-in-out;
}

.excerpt {
    color: $excerpt;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 144%;
    letter-spacing: -0.01em;
    width: 100%;
    max-width: 350px;
    transition: color 0.4s ease-in-out;
    margin: 0.5rem 0 2.5rem;

    &--mobile {
        display: none;
        margin: 0 0 0.5rem;
    }
}

.handler {
    width: 80px;
    padding: 0.375rem;
    border-radius: 6.25rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition:
        cursor 0.4s ease-in-out,
        background-color 0.4s ease;
    flex-shrink: 0;

    &__wrapper {
        display: flex;
        gap: 14px;
        align-items: center;
    }
}

.input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.01px;
    height: 0.01px;
    visibility: hidden;
}

.button {
    position: absolute;
    top: 50%;
    left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0;
    width: 34px;
    height: 34px;
    border-radius: 100px;
    background: #fff;
    transition: transform 0.4s ease-in-out;
    z-index: 5;
}

.icon {
    margin: 7px;
    display: block;
    position: relative;
    z-index: 8;
    width: 18px;
    height: 18px;
}

.impulse {
    position: relative;
}

.try {
    width: 75px;

    img {
        position: relative !important;
        aspect-ratio: 93 / 55;
    }
}

.arrow {
    position: absolute;
    width: 32px;
    bottom: 2px;
    left: 55px;
    transform: translateY(50%);

    img {
        position: relative !important;
        aspect-ratio: 1 / 1;
    }
}

.devices {
    position: relative;
    width: 100%;
    height: 100%;
}

.label {
    position: absolute;
    z-index: 10;
    bottom: 20%;
    right: 33%;
    width: 120px;
    transform: translateX(50%);

    img {
        aspect-ratio: 2 / 1;
        position: relative !important;
    }
}

.desktop {
    display: block;
    border-radius: 4px;
    width: 90%;
    transform: translateY(8%);
}

.mobile {
    position: absolute;
    display: block;
    bottom: 0;
    right: 0;
    width: 33%;
}

@media (max-width: 1750px) {
    .theme {
        &--large {
            .excerpt {
                max-width: 70%;
            }
        }
    }
}

@media (max-width: 1700px) {
    .theme {
        padding: 2.5rem 2.5rem 0;

        &--large {
            padding: 3rem 3rem 0;

            .devices {
                padding-bottom: 3rem;
            }
        }
    }
}

@media (max-width: 1480px) {
    .theme {
        padding: 2rem 2rem 0;

        &--large {
            padding: 2.5rem 2.5rem 0;

            .devices {
                padding-bottom: 2.5rem;
            }

            .theme__top {
                column-gap: 0;
                row-gap: 0.75rem;
                margin-bottom: 3rem;
            }

            .handler__wrapper {
                margin-top: 0.5rem;
            }

            .heading {
                font-size: 2.25rem;
            }

            .excerpt {
                margin: 0;
                max-width: 520px;
            }
        }
    }

    .try {
        width: 65px;
    }

    .arrow {
        width: 25px;
    }

    .theme__top {
        flex-direction: column;
        column-gap: 0;
        row-gap: 0.5rem;
        margin-bottom: 1rem;
    }

    .excerpt {
        display: none;

        &--mobile {
            display: inline-block;
        }
    }
}

@media (max-width: 1400px) {
    .try {
        width: 58px;
    }

    .arrow {
        left: 48px;
    }
}

@media (max-width: 1300px) {
    .try {
        width: 65px;
    }

    .arrow {
        left: 55px;
    }

    .theme {
        &--large {
            .heading {
                font-size: 2.125rem;
            }

            .excerpt {
                font-size: 1.125rem;
            }
        }
    }
}

@media (max-width: 1200px) {
    .theme {
        padding: 1.5rem 1.5rem 0;

        &--large {
            padding: 2rem 2rem 0;

            .devices {
                padding-bottom: 2rem;
            }

            .theme__top {
                row-gap: 0.5rem;
            }

            .heading {
                font-size: 2rem;
            }

            .excerpt {
                font-size: 1rem;
            }
        }
    }

    .heading {
        font-size: 1.5rem;
    }
}

@media (max-width: 1100px) {
    .theme {
        padding: 2.5rem 2.5rem 0;

        &--large {
            padding: 2.5rem 2.5rem 0;

            .devices {
                padding-bottom: 2.5rem;
            }

            .theme__top {
                margin-bottom: 1.5rem;
            }

            .heading {
                font-size: 2.5rem;
            }

            .excerpt {
                font-size: 1.25rem;
                max-width: 70%;
                margin: 0 0 2rem;
            }

            .handler {
                width: 80px;
            }

            .button {
                width: 34px;
                height: 34px;
            }

            .icon {
                width: 18px;
                height: 18px;
            }

            .label {
                width: 120px;
            }

            .desktop {
                width: 90%;
            }

            .arrow {
                bottom: 2px;
            }
        }
    }

    .theme__top {
        flex-direction: row;
        margin-bottom: 1.5rem;
    }

    .heading {
        font-size: 2.5rem;
    }

    .excerpt {
        font-size: 1.25rem;
        max-width: 75%;
        display: block;
        margin: 0 0 2rem;

        &--mobile {
            display: none;
        }
    }

    .try {
        width: 72px;
    }

    .arrow {
        width: 32px;
    }

    .devices {
        padding-bottom: 2.5rem;
    }

    .desktop {
        transform: none;
    }
}

@media (max-width: 900px) {
    .theme {
        &--large {
            .heading {
                font-size: 2rem;
            }

            .try {
                width: 65px;
            }

            .arrow {
                width: 28px;
                left: 50px;
            }
        }
    }

    .heading {
        font-size: 2rem;
    }

    .try {
        width: 65px;
    }

    .arrow {
        width: 28px;
        left: 50px;
    }

    .label {
        bottom: 30%;
    }
}

@media (max-width: 768px) {
    .theme {
        &--large {
            .heading {
                font-size: 1.75rem;
            }

            .excerpt {
                max-width: 60%;
            }
        }
    }

    .heading {
        font-size: 1.75rem;
    }
}

@media (max-width: 576px) {
    .theme {
        padding: 1.5rem 1.5rem 0;

        &--large {
            padding: 1.5rem 1.5rem 0;

            .devices {
                padding-bottom: 1.5rem;
            }

            .heading {
                font-size: 1.5rem;
            }

            .excerpt {
                display: none;
                font-size: 1rem;
                max-width: 90%;
                margin: 0;

                &--mobile {
                    display: inline-block;
                }
            }
        }
    }

    .theme__top {
        flex-direction: column;
    }

    .heading {
        font-size: 1.5rem;
    }

    .excerpt {
        display: none;
        font-size: 1rem;
        max-width: 90%;
        margin: 0;

        &--mobile {
            display: inline-block;
        }
    }

    .devices {
        padding-bottom: 1.5rem;
    }

    .handler__wrapper {
        margin-top: 0.5rem;
    }
}

@media (max-width: 480px) {
    .theme {
        &--large {
            .excerpt {
                max-width: 100%;
            }

            .label {
                width: 100px;
            }
        }
    }

    .excerpt {
        max-width: 100%;
    }

    .label {
        width: 100px;
    }
}

@media (max-width: 376px) {
    .theme {
        padding: 1.25rem 1.25rem 0;

        &--large {
            padding: 1.25rem 1.25rem 0;

            .heading {
                font-size: 1.25rem;
            }

            .excerpt {
                font-size: 0.875rem;
            }
        }
    }

    .heading {
        font-size: 1.25rem;
    }

    .excerpt {
        font-size: 0.875rem;
    }
}
