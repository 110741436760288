@import '../../../styles/vars';

.section__container {
    border-radius: 6px;
    background: #f8f8f9;
    max-height: 680px;
    height: 35.42vw;
    margin-bottom: 9rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0 4rem;
    gap: 4rem;
}

.section__left {
    padding: 4rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    max-width: 810px;
}

.heading {
    color: $dark;
    padding-bottom: 1rem;
    max-width: 610px;
}

.excerpt {
    max-width: 600px;
}

.background {
    width: 42vw;
    height: auto;
    aspect-ratio: 101 / 57;
    border-radius: 4px;
    img {
        position: relative !important;
        aspect-ratio: 101 / 57;
        border-radius: 4px;
        box-shadow: 0px 8px 50px 0px rgba(10, 22, 60, 0.08);
    }
}

.swiper {
    position: relative !important;
    overflow: visible;
    opacity: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    animation: fadeIn 0.6s ease-in-out 1s forwards;
}

@media (min-width: 1921px) {
    .background {
        width: 808px;
    }

    .swiper {
        position: relative !important;
        top: 0;
        margin: 0;
    }
}

@media (max-width: 1700px) {
    .section__container {
        margin-bottom: 7.5rem;
    }

    .section__left {
        max-width: 740px;
    }
}

@media (max-width: 1480px) {
    .section__container {
        margin-bottom: 6.5rem;
        height: 36vw;
        padding: 0 3rem;
    }

    .heading {
        max-width: 585px;
    }

    .section__left {
        max-width: 53.5%;
        padding: 3rem 0;
    }

    .background {
        width: 35.44vw;
    }

    .swiper {
        margin: 0;
    }
}

@media (max-width: 1280px) {
    .section__container {
        gap: 3rem;
    }

    .heading {
        max-width: 480px;
    }
}

@media (max-width: 1200px) {
    .section__container {
        margin-bottom: 5rem;
        height: 40vw;
        padding: 0 2.5rem;
        gap: 2.5rem;
    }

    .section__left {
        max-width: 100%;
        width: 53%;
        padding: 2.5rem 0;
    }
}

@media (max-width: 1024px) {
    .section__container {
        height: auto;
        flex-direction: column;
        padding: 0 0 2rem;
        max-height: 100%;
        gap: 0;
    }

    .section__container {
        height: auto;
        flex-direction: column;
        padding: 0 0 2rem;
        max-height: 100%;
        gap: 0;
    }

    .section__left {
        align-self: center;
        text-align: center;
        max-width: 37rem;
        padding: 2rem;
    }

    .section__info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .heading {
        max-width: 415px;
    }

    .excerpt {
        max-width: 425px;
    }
    .swiper {
        margin: 0;
        right: 0;
        transform: translate(0);
        position: relative;

        .swiper-wrapper {
            height: auto;
            width: auto;
        }
    }

    .background {
        width: 480px;
        height: auto;
    }

    //OLD
    .section__left {
        max-width: 34rem;
        width: auto;
        padding: 2rem 0;
    }

    .swiper {
        right: 0;
        transform: translateX(10%);
    }
}

//@media (max-width: 840px) {
//    .background {
//        width: 33.67vw;
//    }
//}

//@media (max-width: 820px) {
//    .section__container {
//        height: auto;
//        flex-direction: column;
//        padding: 0 0 2rem;
//        max-height: 100%;
//        gap: 0;
//    }

//    .section__left {
//        align-self: center;
//        text-align: center;
//        max-width: 37rem;
//        padding: 2rem;
//    }

//    .section__info {
//        display: flex;
//        flex-direction: column;
//        align-items: center;
//    }

//    .heading {
//        max-width: 415px;
//    }

//    .excerpt {
//        max-width: 425px;
//    }
//    .swiper {
//        margin: 0;
//        right: 0;
//        transform: translate(0);
//        position: relative;

//        .swiper-wrapper {
//            height: auto;
//            width: auto;
//        }
//    }

//    .background {
//        width: 480px;
//        height: auto;
//    }
//}

@media (max-width: 768px) {
    .section__container {
        margin-bottom: 4rem;
    }
}

@media (max-width: 576px) {
    .section__container {
        margin-bottom: 3rem;
    }

    .section__left {
        padding: 2rem 1.5rem 2rem;
        height: auto;
    }

    .background {
        width: 350px;
    }
}

@media (max-width: 480px) {
    .background {
        width: 320px;
    }

    .heading {
        padding-bottom: 0.5rem;
    }
}

@media (max-width: 414px) {
    .section__container {
        padding-bottom: 1.5rem;
    }

    .section__left {
        padding: 1.5rem;
    }
}

@media (max-width: 376px) {
    .section__container {
        padding-bottom: 1.25rem;
    }

    .section__left {
        padding: 1.25rem;
    }

    .background {
        width: 280px;
    }
}

@media (max-width: 340px) {
    .section__container {
        height: 160vw;
    }
}
