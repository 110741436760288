@import '../../styles/vars';
@import '../../styles/keyframes.scss';

.card {
    display: block;
}

.card__wrapper {
    position: relative;
    border-radius: 4px;
    border: #f4f4f4;
    background: #f8f8f9;
    height: auto;
    overflow: hidden;
    margin-bottom: 1.5rem;
    cursor: pointer;
    transition: $anim;
    overflow: visible;
}

.hover__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1.25rem;
    background-color: rgba(0, 0, 0, 0);
    top: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.75rem;
    will-change: background-color;
    transition: background-color 0.35s ease-in-out;
    z-index: -10;
    cursor: default;
    border-radius: 4px;
}

.hover__learn {
    opacity: 0;
    pointer-events: none;
}

.hover__preview {
    opacity: 0;
    pointer-events: none;
}

.card__wrapper:hover .hover__wrapper {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;

    .hover__learn {
        animation:
            tinyFadeIn 0.3s ease-in-out 0.1s forwards,
            pointerEventsActivate 0.3s ease 0.1s forwards;
    }

    .hover__preview {
        animation:
            tinyFadeIn 0.3s ease-in-out 0.1s forwards,
            pointerEventsActivate 0.3s ease 0.1s forwards;
    }
}

.card__img {
    transition: transform 0.4s ease-in-out;
    position: relative !important;
    border-radius: 4px;
    img {
        display: block;
        position: relative !important;
        aspect-ratio: 1 / 1;
        border-radius: 4px;
        background: #f8f8f9;
        box-shadow: 0px 8px 40px 0px rgba(10, 22, 60, 0.08);
    }
}

.backlog {
    margin: auto;
    width: 100%;
    background: #d9d9d9;
    height: 100%;
    border-radius: 4px 4px 0 0;
    background: #d9d9d9;
    aspect-ratio: 1 / 1;
}

.card__info {
    max-width: 33.4375rem;
}

.info__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    // margin-bottom: 1rem;
}

.info__tags {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.demo__name {
    display: inline-block;
    color: $excerpt;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.01em;
}

.theme__name {
    transition: color 0.4s ease-in-out;
    color: $dark;
    font-size: 1.25rem !important;
}

.demo__tag {
    border-radius: 100px;
    background: #ebecef;
    padding: 0.3125rem 0.6875rem;
    color: $dark;
    font-size: 13px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    display: block;
    transition: background 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
        background: #e4e5ea;
    }
}

@media (max-width: 768px) {
    .backlog {
        width: 44.3vw;
    }

    .card__info {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .backlog {
        width: 90vw;
    }

    .theme__name {
        font-size: 1.375rem;
    }

    .demo__tag {
        padding: 0.375rem 0.75rem;
    }
}

@media (max-width: 414px) {
    // .info__top {
    //     margin-bottom: 0.5rem;
    // }
}
