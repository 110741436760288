@import '../../../styles/vars';
@import '../../../styles/keyframes.scss';

.section__container {
    background: #0d0e14;
    border-radius: 6px;
    max-height: 680px;
    height: 35.42vw;
    margin-bottom: 9rem;
    position: relative;
    overflow: hidden;
}

.section__left {
    padding: 4rem 0 4rem 4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 750px;
}

.logo__wrapper {
    width: 3.5rem;
    height: 3.5rem;
    position: relative !important;
    img {
        aspect-ratio: 1 / 1;
        position: relative !important;
    }
}

.heading {
    color: $white;
    padding-bottom: 1rem;
}

.excerpt {
    color: #bbbbbc;
    max-width: 650px;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.background {
    position: absolute;
    top: 0;
    right: -46%;
    width: 104.65vw;
    img {
        position: relative !important;
        aspect-ratio: 518 / 299;
    }
}

@media (min-width: 1921px) {
    .background {
        width: 2009.28px;
    }
}

@media (max-width: 1700px) {
    .section__container {
        margin-bottom: 7.5rem;
        height: 35vw;
    }

    .section__left {
        max-width: 710px;
    }

    .background {
        right: -47%;
    }
}

@media (max-width: 1480px) {
    .section__container {
        margin-bottom: 6.5rem;
    }

    .excerpt {
        max-width: 590px;
    }

    .section__left {
        padding: 3rem 0 3rem 3rem;
    }

    .background {
        width: 95vw;
        top: 8%;
        right: -45%;
    }

    .logo__wrapper {
        width: 3rem;
        height: 3rem;
    }
}

@media (max-width: 1200px) {
    .section__container {
        margin-bottom: 5rem;
        height: 36vw;
    }

    .section__left {
        padding: 2.5rem 0 2.5rem 2.5rem;
        max-width: 55%;
    }

    .logo__wrapper {
        width: 2.5rem;
        height: 2.5rem;
    }

    .heading {
        padding-bottom: 0.75rem;
    }

    .background {
        width: 120vw;
        top: -13%;
        right: -70%;
    }
}

@media (max-width: 1024px) {
    .section__container {
        height: 42vw;
    }

    .section__left {
        padding: 2rem 0 2rem 2rem;
        max-width: 57%;
    }

    .background {
        top: 3%;
        right: -69%;
        opacity: 0.75;
    }

    .buttons {
        position: relative;
        z-index: 5;
    }
}

@media (max-width: 900px) {
    .section__container {
        height: 43vw;
    }

    .section__left {
        max-width: 63%;
    }
}

@media (max-width: 820px) {
    .section__container {
        height: 70vw;
    }

    .section__info {
        padding-top: 2rem;
    }

    .section__left {
        width: auto;
        justify-content: flex-start;
        max-width: 30rem;
        height: auto;
    }

    .background {
        top: 37%;
        width: 130vw;
        right: -59%;
    }
}

@media (max-width: 768px) {
    .section__container {
        margin-bottom: 4rem;
        height: 78vw;
    }

    .background {
        top: 39%;
        width: 140vw;
        right: -63%;
    }
}

@media (max-width: 640px) {
    .section__container {
        height: 85vw;
    }

    .background {
        top: 40%;
        width: 150vw;
        right: -69%;
    }
}

@media (max-width: 576px) {
    .section__container {
        height: 600px;
        margin-bottom: 3rem;
    }

    .section__info {
        padding: 1.5rem 1.5rem 0 0;
    }

    .section__left {
        max-width: 100%;
        padding: 1.5rem;
    }

    .background {
        top: 38%;
        left: -50px;
        right: auto;
        // right: -72%;
        width: 1000px;
    }

    .buttons {
        flex-wrap: wrap;
    }
}

@media (max-width: 480px) {
    .section__container {
        height: 570px;
    }

    .section__info {
        padding: 1.5rem 0 0 0;
    }

    .logo__wrapper {
        width: 2rem;
        height: 2rem;
    }

    // .background {
    //     top: 52%;
    //     right: -69%;
    // }

    .buttons {
        a,
        button {
            width: 100%;
        }
    }

    .heading {
        width: 95%;
    }

    .excerpt {
        width: 95%;
        padding-bottom: 1.5rem;
    }
}

@media (max-width: 414px) {
    .section__container {
        height: 450px;
    }

    .background {
        top: 46%;
        left: -114px;
        width: 900px;
    }

    .section__left {
        padding: 1.25rem;
    }
}

@media (max-width: 376px) {
    .heading {
        width: 100%;
    }

    .excerpt {
        width: 100%;
        padding-bottom: 1rem;
    }
}
