@import '../../styles/vars';

.breadcrumbs {
    padding: 2rem 0 0;
    display: flex;
    align-items: center;
    gap: 0.1875rem;
}

.link {
    position: relative;
    padding: 0.125rem 0;
    text-transform: capitalize;
    color: #3b4563;
    font-size: 14px;
    letter-spacing: -0.02em;
    font-weight: 500;
    cursor: pointer;
    transition: $anim;

    &:hover:not(:last-child) {
        color: #3b4563;
        &::after {
            width: 100%;
        }
    }

    &:last-child {
        color: #9da2b1;
    }
}

.devider {
    width: 0.875rem;
    height: 0.875rem;
    background-image: url('../../../public/images/arrow.svg');
    background-size: contain;
    background-position: center center;
    display: block;
}

@media (max-width: 768px) {
    .breadcrumbs {
        display: none;
    }
}
