@import '../../../styles/vars';
@import '../../../styles/keyframes.scss';

.section__container {
    border-bottom: 1px solid #e4e4e4;
    background: $white;
    margin-bottom: 9rem;
    height: 44.25rem;
    overflow: hidden;
}

.section__wrapper {
    display: flex;
    height: 100%;
}

.section__left {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 47.6%;
    padding-top: 5.4375rem;
}

.section__info>div {
    opacity: 0;
    animation: fadeIn 0.6s ease-in-out 1s forwards;
}

.logo-wrapper {
    height: 32px;
    margin-bottom: 2rem;
    opacity: 0;
    animation: fadeIn 0.6s ease-in-out 0.5s forwards;
}

.logo {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    width: auto !important;
}

.heading {
    text-align: left;
    color: $dark;
    padding-bottom: 1.5rem;
    line-height: 73%;

    .word__wrapper {
        line-height: 100%;

        span {
            transform: translateY(100%);
            line-height: 112%;
        }
    }

    span {
        display: inline-block;
    }
}

.word__wrapper {
    overflow-y: hidden;
    overflow-x: auto;
}

.animate__heading {
    .word__wrapper span {
        animation: $headingFade;
    }
}

.animate__excerpt {
    line-height: 100%;

    .word__wrapper span {
        animation: $excerptFade;
    }
}

.excerpt {
    max-width: 604px;
    line-height: 73%;

    .word__wrapper {
        line-height: 100%;

        span {
            transform: translateY(100%);
            line-height: 140%;
        }
    }

    span {
        display: inline-block;
    }
}

.theme__right {
    width: 52.4%;
    position: relative;
}

.theme__mobile {
    left: 0;
    width: 21.4%;
    position: absolute;
    bottom: -20px;
    border-radius: 1rem;
    z-index: 5;
    background: $white;

    img {
        aspect-ratio: 204 / 413;
        position: relative !important;
        border-radius: 1rem;
        border: 5px solid $dark;
    }

    opacity: 0;
    transform: scale(0.97);
    will-change: transform;

    animation-name: cardAnimation,
    fadeIn;
    animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1),
    cubic-bezier(0, 0, 0.2, 1);
    animation-duration: 0.6s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.theme__desktop {
    bottom: 0;
    right: 0;
    width: 85.56%;
    position: absolute;
    z-index: 0;
    border-radius: 4px;

    img {
        aspect-ratio: 160 / 131;
        position: relative !important;
        border-radius: 4px;
        box-shadow: 0px 8px 50px 0px rgba(10, 22, 60, 0.08);
    }

    opacity: 0;
    transform: scale(0.97);
    will-change: transform;

    animation-name: cardAnimation,
    fadeIn;
    animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1),
    cubic-bezier(0, 0, 0.2, 1);
    animation-duration: 0.8s;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
}

@media (max-width: 1700px) {
    .section__container {
        margin-bottom: 7.5rem;
        height: 42rem;
    }

    .section__left {
        justify-content: flex-end;
        padding-top: 0;
        padding-right: 1rem;
        padding-bottom: 4rem;
    }

    .theme__desktop {
        width: 94%;
    }

    .theme__mobile {
        width: 22%;
    }

    .excerpt {
        max-width: 650px;
    }
}

@media (max-width: 1480px) {
    .section__container {
        margin-bottom: 6.5rem;
    }

    .section__container {
        height: 38rem;
    }

    .section__left {
        padding-bottom: 4rem;
    }

    .excerpt {
        max-width: 550px;
    }
}

@media (max-width: 1280px) {
    .section__container {
        height: 34rem;
    }

    .section__left {
        padding-bottom: 3rem;
    }

    .logo-wrapper {
        margin-bottom: 1.5rem;
    }

    .heading {
        padding-bottom: 1rem;
    }
}

@media (max-width: 1200px) {
    .section__container {
        margin-bottom: 5rem;
    }

    .heading {
        padding-bottom: 0.75rem;
    }

    .section__left {
        width: 47%;
    }

    .theme__right {
        width: 53%;
    }

    .theme__desktop {
        width: 97%;
    }

    .theme__mobile {
        width: 23%;
    }

    .excerpt {
        max-width: 484px;
    }
}

@media (max-width: 1060px) {
    .section__left {
        width: 51%;
    }

    .theme__right {
        width: 49%;
    }
}

@media (max-width: 1024px) {
    .section__container {
        height: auto;
        padding-top: 3rem;
    }

    .section__wrapper {
        flex-direction: column;
        height: auto;
    }

    .section__left {
        height: auto;
        width: auto;
        max-width: 36rem;
        padding-right: 0;
        padding-bottom: 3rem;
    }

    .theme__right {
        width: 100%;
        height: 45vw;
        padding: 2rem 2rem 0;
    }

    .theme__desktop {
        width: 65%;
        top: 3rem;
        right: 4rem;
        bottom: auto;
    }

    .theme__mobile {
        width: 24%;
        left: 4rem;
        bottom: -30%;
    }
}

@media (max-width: 768px) {
    .theme__right {
        height: 54vw;
    }

    .theme__mobile {
        bottom: -15%;
    }

    .section__container {
        margin-bottom: 4rem;
    }
}

@media (max-width: 640px) {
    .theme__desktop {
        right: 3rem;
    }

    .theme__mobile {
        left: 3rem;
    }
}

@media (max-width: 576px) {
    .section__container {
        margin-bottom: 3rem;
    }

    .theme__right {
        padding: 2.5rem 2.5rem 0;
        height: 60vw;
    }

    .section__left {
        padding-bottom: 2rem;
    }

    .theme__desktop {
        right: 2.5rem;
        width: 73%;
        top: 2.5rem;
    }

    .theme__mobile {
        left: 2.5rem;
        bottom: -20%;
        width: 25.6%;
    }
}

@media (max-width: 480px) {
    .logo-wrapper {
        margin-bottom: 1.25rem;
    }

    .theme__right {
        padding: 1.5rem 1.5rem 0;
    }

    .theme__desktop {
        right: 1.5rem;
        width: 75.55%;
        top: 2rem;
    }

    .theme__mobile {
        left: 1.5rem;
        bottom: -22%;
        width: 26.7%;
    }
}

@media (max-width: 414px) {
    .theme__desktop {
        top: 1.5rem;
    }

    .section__left {
        padding-bottom: 1.5rem;
    }

    .theme__mobile {
        img {
            border-radius: 0.5rem;
            border: 3px solid $dark;
        }
    }
}

@media (max-width: 376px) {
    .theme__right {
        padding: 1.25rem 1.25rem 0;
    }

    .theme__desktop {
        top: 1.25rem;
        right: 1.25rem;
        width: 80%;
    }

    .theme__mobile {
        left: 1.25rem;
        width: 29.2%;
        bottom: -29%;
    }
}