@import '../../styles/vars';

.feature {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: #f5f6f7;
    overflow: hidden;
    position: relative;
    z-index: 1;
    animation-duration: 0.5s;
    opacity: 0;
    animation-fill-mode: forwards;

    &--animate {
        animation-name: fadeInUp, fadeIn;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1), cubic-bezier(0, 0, 0.2, 1);
    }

    &--large {
        .featureMain {
            padding: 4rem 4rem 0;

            &__title {
                font-size: 2.5rem;
            }

            &__desc {
                font-size: 1.25rem;
                max-width: 520px;
            }
        }

        .demos-wrapper {
            padding: 2rem 0 0 4rem;
        }

        .demos {
            &__mainImage {
                width: 55.2% !important;
            }

            &__block {
                width: 55.2%;
                top: calc(var(--i) * 13.8%);
                left: calc(var(--i) * 8%);
            }

            &__count {
                top: 12%;
                right: 4rem;
                width: 158px;
                height: 158px;

                &__number {
                    font-size: 4.625rem;
                }

                &__desc {
                    font-size: 1rem;
                }
            }
        }
    }
}

.featureMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    padding: 3rem 3rem 0;

    &__title {
        font-size: 1.75rem;
        line-height: 120%;
        letter-spacing: -0.03em;
        font-weight: 600;
    }

    &__desc {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 144%;
        letter-spacing: -0.01em;
        color: $excerpt;
        width: 100%;
        max-width: 380px;
    }
}

.featureImgWrapper {
    width: 100%;
    position: relative;
    margin-top: auto;
}

.featureImg {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    width: 100% !important;
    height: auto !important;
}

.demos-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    padding: 2rem 0 0 3rem;
}

.demos {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    &__mainImage {
        top: 0 !important;
        left: 0 !important;
        width: 75% !important;
        height: auto !important;
        border-radius: 0.25rem;
        box-shadow: 0px 8px 50px 0px rgba(10, 22, 60, 0.08);
        z-index: 100;
    }

    &__block {
        position: absolute;
        width: 75%;
        aspect-ratio: 225 / 338;
        border-radius: 4px;
        background: #fff;
        box-shadow: 0px 8px 50px 0px rgba(10, 22, 60, 0.08);
        top: calc(var(--i) * 10%);
        left: calc(var(--i) * 4%);
        z-index: calc(99 - var(--i) - 1);
    }

    &__count {
        position: absolute;
        top: 7%;
        right: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: $dark;
        z-index: 1000;

        &__number {
            color: $white;
            font-size: 3.5rem;
            font-weight: 700;
            line-height: 1;
            letter-spacing: 0.03em;
        }

        &__desc {
            color: $white;
            font-size: 0.875rem;
            font-weight: 700;
            line-height: 1;
            letter-spacing: 0.08em;
            text-transform: uppercase;
        }
    }
}

@media (max-width: 1700px) {
    .feature {
        .featureMain {
            padding: 2.5rem 2.5rem 0;
        }

        &--large {
            .featureMain {
                padding: 3rem 3rem 0;

                &__title {
                    font-size: 2.25rem;
                }
            }

            .demos-wrapper {
                padding: 2rem 0 0 3rem;
            }

            .demos {
                &__count {
                    width: 140px;
                    height: 140px;
                    top: 10%;
                    right: 3rem;

                    &__number {
                        font-size: 4rem;
                    }

                    &__desc {
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }

    .demos-wrapper {
        padding: 2rem 0 0 2.5rem;
    }

    .demos {
        &__count {
            width: 110px;
            height: 110px;
            right: 2.5rem;

            &__number {
                font-size: 3rem;
            }

            &__desc {
                font-size: 0.75rem;
            }
        }
    }
}

@media (max-width: 1480px) {
    .feature {
        .featureMain {
            padding: 2rem 2rem 0;
        }

        &--large {
            .featureMain {
                padding: 2.5rem 2.5rem 0;
            }

            .demos-wrapper {
                padding: 2rem 0 0 2.5rem;
            }

            .demos {
                &__count {
                    right: 2.5rem;
                }
            }
        }
    }

    .demos-wrapper {
        padding: 2rem 0 0 2rem;
    }

    .demos {
        &__count {
            width: 96px;
            height: 96px;
            right: 2rem;

            &__number {
                font-size: 2.75rem;
            }

            &__desc {
                font-size: 0.625rem;
            }
        }
    }
}

@media (max-width: 1300px) {
    .feature {
        &--large {
            .featureMain {
                &__title {
                    font-size: 2.125rem;
                }

                &__desc {
                    font-size: 1.125rem;
                }
            }

            .demos {
                &__count {
                    width: 110px;
                    height: 110px;
                    right: 2.5rem;

                    &__number {
                        font-size: 3rem;
                    }

                    &__desc {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .feature {
        .featureMain {
            padding: 1.5rem 1.5rem 0;
            row-gap: 0.5rem;

            &__title {
                font-size: 1.5rem;
            }
        }

        &--large {
            .featureMain {
                padding: 2rem 2rem 0;

                &__title {
                    font-size: 2rem;
                }

                &__desc {
                    font-size: 1rem;
                }
            }

            .demos-wrapper {
                padding: 2rem 0 0 2rem;
            }

            .demos {
                &__count {
                    width: 100px;
                    height: 100px;
                    right: 2rem;
                }
            }
        }
    }

    .demos-wrapper {
        padding: 2rem 0 0 1.5rem;
    }

    .demos {
        &__count {
            width: 80px;
            height: 80px;
            right: 1.5rem;

            &__number {
                font-size: 2.25rem;
            }

            &__desc {
                font-size: 0.625rem;
            }
        }
    }
}

@media (max-width: 1100px) {
    .feature {
        .featureMain {
            padding: 2.5rem 2.5rem 0;

            &__title {
                font-size: 2.5rem;
            }

            &__desc {
                font-size: 1.25rem;
                max-width: 80%;
            }
        }

        &--large {
            .featureMain {
                padding: 2.5rem 2.5rem 0;

                &__title {
                    font-size: 2.5rem;
                }

                &__desc {
                    font-size: 1.25rem;
                    max-width: 80%;
                }
            }

            .demos-wrapper {
                padding: 2rem 0 0 2.5rem;
                height: 50vw;
            }

            .demos {
                &__mainImage {
                    width: 55.2% !important;
                }

                &__block {
                    width: 55.2%;
                    top: calc(var(--i) * 13.8%);
                    left: calc(var(--i) * 8%);
                }

                &__count {
                    width: 140px;
                    height: 140px;
                    right: 2.5rem;
                    top: 7%;

                    &__number {
                        font-size: 3.5rem;
                    }

                    &__desc {
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }

    .demos-wrapper {
        padding: 2rem 0 0 2.5rem;
        height: 50vw;
    }

    .demos {
        &__count {
            width: 140px;
            height: 140px;
            right: 2.5rem;

            &__number {
                font-size: 3.5rem;
            }

            &__desc {
                font-size: 0.875rem;
            }
        }
    }
}

@media (max-width: 900px) {
    .feature {
        .featureMain {
            &__title {
                font-size: 2rem;
            }
        }

        &--large {
            .featureMain {
                &__title {
                    font-size: 2rem;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .feature {
        .featureMain {
            &__title {
                font-size: 1.75rem;
            }

            &__desc {
                max-width: 90%;
            }
        }

        &--large {
            .featureMain {
                &__title {
                    font-size: 1.75rem;
                }

                &__desc {
                    max-width: 90%;
                }
            }

            .demos {
                &__count {
                    width: 96px;
                    height: 96px;
                    right: 2rem;

                    &__number {
                        font-size: 2.75rem;
                    }

                    &__desc {
                        font-size: 0.625rem;
                    }
                }
            }
        }
    }

    .demos {
        &__count {
            width: 96px;
            height: 96px;
            right: 2rem;

            &__number {
                font-size: 2.75rem;
            }

            &__desc {
                font-size: 0.625rem;
            }
        }
    }
}

@media (max-width: 576px) {
    .feature {
        .featureMain {
            padding: 1.5rem 1.5rem 0;

            &__title {
                font-size: 1.5rem;
            }

            &__desc {
                font-size: 1rem;
                max-width: 90%;
            }
        }

        &--large {
            .featureMain {
                padding: 1.5rem 1.5rem 0;

                &__title {
                    font-size: 1.5rem;
                }

                &__desc {
                    font-size: 1rem;
                    max-width: 90%;
                }
            }

            .demos-wrapper {
                padding: 2rem 0 0 1.5rem;
                height: 60vw;
            }

            .demos {
                &__count {
                    width: 80px;
                    height: 80px;
                    right: 1.5rem;
                    top: 2%;

                    &__number {
                        font-size: 2.25rem;
                    }

                    &__desc {
                        font-size: 0.625rem;
                    }
                }
            }
        }
    }

    .demos-wrapper {
        padding: 2rem 0 0 1.5rem;
    }

    .demos-wrapper {
        height: 60vw;
    }

    .demos {
        &__count {
            width: 80px;
            height: 80px;
            right: 1.5rem;
            top: 2%;

            &__number {
                font-size: 2.25rem;
            }

            &__desc {
                font-size: 0.625rem;
            }
        }
    }
}

@media (max-width: 480px) {
    .feature {
        .featureMain {
            &__desc {
                max-width: 100%;
            }
        }

        &--large {
            .featureMain {
                &__desc {
                    max-width: 100%;
                }
            }
        }
    }
}

@media (max-width: 376px) {
    .feature {
        .featureMain {
            padding: 1.25rem 1.25rem 0;

            &__title {
                font-size: 1.25rem;
            }

            &__desc {
                font-size: 0.875rem;
            }
        }

        &--large {
            .featureMain {
                padding: 1.25rem 1.25rem 0;

                &__title {
                    font-size: 1.25rem;
                }

                &__desc {
                    font-size: 0.875rem;
                }
            }

            .demos-wrapper {
                padding: 2rem 0 0 1.25rem;
            }
        }
    }

    .demos-wrapper {
        padding: 2rem 0 0 1.25rem;
    }
}
